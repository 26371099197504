import { navigate } from "gatsby";
import React, { createContext, ReactNode, useState } from "react";
import questionList from "../data/questions.json";

type answerParams = {
  answer: any;
  wallId?: number;
  childIdList?: number[];
};

type ContextProps = {
  currentQuestion: number;
  onNextPage: VoidFunction;
  onPrevPage: VoidFunction;
  showModal: boolean;
  openModal: VoidFunction;
  closeModal: VoidFunction;
  modalData: any;
  setModalData: React.Dispatch<any>;
  setAnswer: (param: answerParams) => void;
  answerData: any;
  wallData: any;
  childQuestionId: number[];
  setChildQuestionId: React.Dispatch<React.SetStateAction<number[]>>;
  currentChildQuestion: number;
  setCurrentChildQuestion: React.Dispatch<React.SetStateAction<number>>;
};

type Props = {
  children: ReactNode;
};

const initialWallData = [
  [questionList[0].answers, questionList[1].answers, questionList[2].answers],
  [questionList[3].answers],
  [questionList[4].answers],
  [questionList[5].answers],
];

export const MainContext = createContext<ContextProps>({
  currentQuestion: 0,
  onNextPage: () => {},
  onPrevPage: () => {},
  modalData: {},
  setModalData: () => {},
  setAnswer: () => {},
  showModal: false,
  openModal: () => {},
  closeModal: () => {},
  answerData: [],
  wallData: [],
  childQuestionId: [],
  setChildQuestionId: () => {},
  currentChildQuestion: 0,
  setCurrentChildQuestion: () => {},
});

export const MainProvider = ({ children }: Props) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [modalData, setModalData] = useState();
  const [childQuestionId, setChildQuestionId] = useState<number[]>([]);
  const [currentChildQuestion, setCurrentChildQuestion] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [answerData, setAnswerData] = useState<any[]>(
    questionList.map(item => item.answers)
  );
  const [wallData, setWallData] = useState(initialWallData);

  const setAnswer = ({ answer, wallId, childIdList = [] }: answerParams) => {
    if (wallId !== undefined && wallId > -1) {
      const wallPictures = [...initialWallData];
      const wallQuestionIndex = currentQuestion < 3 ? currentQuestion : 0;
      wallData[wallId][wallQuestionIndex].forEach((_, index) => {
        wallPictures[wallId][wallQuestionIndex][index].selected = answer[index];
      });
      setWallData(wallPictures);
    }

    const tempAnswerData = [...answerData];
    switch (childIdList?.length) {
      case 0:
        tempAnswerData[currentQuestion].forEach((_: any, index: number) => {
          tempAnswerData[currentQuestion][index].selected = answer[index];
        });
        break;
      case 1:
        tempAnswerData[currentQuestion][childIdList[0]].childQuestions[
          currentChildQuestion
        ].answers.forEach((_: any, index: number) => {
          tempAnswerData[currentQuestion][childIdList[0]].childQuestions[
            currentChildQuestion
          ].answers[index].selected = answer[index];
        });
        break;
      case 2:
        tempAnswerData[currentQuestion][
          childIdList[0]
        ].childQuestions[0].answers[
          childIdList[1]
        ].childQuestions[0].answers.forEach((_: any, index: number) => {
          tempAnswerData[currentQuestion][
            childIdList[0]
          ].childQuestions[0].answers[childIdList[1]].childQuestions[0].answers[
            index
          ].selected = answer[index];
        });
        break;
      default:
        break;
    }
    setAnswerData([...tempAnswerData]);
  };

  const onNextPage = () => {
    if (currentQuestion === 8) {
      return navigate("/result");
    }

    setCurrentQuestion(prevPageNum => ++prevPageNum);
  };

  const onPrevPage = () => {
    if (currentQuestion === 0) return;
    setCurrentQuestion(prevPageNum => --prevPageNum);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setChildQuestionId([]);
    setCurrentChildQuestion(0);
    setShowModal(false);
  };

  return (
    <MainContext.Provider
      value={{
        currentQuestion,
        onNextPage,
        onPrevPage,
        modalData,
        setModalData,
        showModal,
        openModal,
        closeModal,
        setAnswer,
        answerData,
        wallData,
        childQuestionId,
        setChildQuestionId,
        currentChildQuestion,
        setCurrentChildQuestion,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
